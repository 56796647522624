<template>
  <div>
    <EditSupplier
      @editDetail="editSupplier"
      @cancelEdit="cancelEdit"
      :supplierId="supplierId"
    />
  </div>
</template>

<script>
import EditSupplier from "../../../components/WebStore/suppliers/EditSupplier";
import { mapActions } from "vuex";

export default {
  name: "Edit-Supplier",
  components: {
    EditSupplier
  },
  data: () => ({
    supplierId: ""
  }),
  methods: {
    ...mapActions("supplier", ["updateSupplierDetails"]),
    editSupplier(data) {
      let self = this;
      let value = {
        id: this.supplierId,
        info: data
      };
      this.updateSupplierDetails(value).then(res => {
        self.$vs.notify({
          title: "Supplier Edit",
          text: "Supplier edited successfully",
          color: "success"
        });
        self.$vs.loading.close();
        this.$router.push({ name: "superAdminStoreSuppliers" });
      });
    },
    cancelEdit(id) {
      this.$router.push({
        name: "superAdminStoreViewSuppliers",
        params: { supplierId: id }
      });
    }
  },
  async created() {
    this.supplierId = this.$route.params.supplierId;
  }
};
</script>
